<!--Header section start-->
<app-header></app-header>
<!--Header section end-->

<!--Middle content start-->
<div class="container middle_container">
  <div class="row">
    <div class="col-md-12 middle_heading">
      <span> Transaction Tracker</span>
      <p>The Transaction Pin (TF PIN) can be found on sender receipt</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label for="TFPIN" class="lbltfpin">TF Pin</label>
        <input id="tfpin" type="text" name="tfpin" class="form-control txttfpin" pattern="^[a-zA-Z0-9]+$"
          [(ngModel)]="tfpin" placeholder="TF12345678" (keyup)="TfpinOnKeyUp()" />
      </div>
      <div class="form-group">
        <button type="button" id="btnSubmit" class="btn btn-default" (click)="GetTransactionStatus()">
          Track
        </button>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-12">
          <div class="wrapper">
            <div class="row">
              <div class="col-md-4" *ngIf="isDivShow">
                <h4>TF PIN</h4>
                <label for="ptfpin" class="ptfpin">{{ tfpin }}</label>
              </div>
              <div class="col-md-8" *ngIf="isDivShow">
                <p>{{ transactionStatusResult.trackerStatus }}</p>
                <span>{{ transactionStatusResult.trackerStatusDesc }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <p class="disclaimer">
            Transfast services are provided by Mastercard Transaction Services
            (US) LLC, authorized as an International Electronic Payment System
            by the Central Bank of Jordan, or through a subsidiary or affiliate.
            <a href="https://b2b.mastercard.com/cross-border-services/legal/" target="_blank" class="disclaimer_link"
              rel="noopener">Terms and conditions</a>
            apply. Services are subject to availability and certain restrictions
            and Transfast reserves the right to change, from time to time, in
            Transfast`s sole discretion, the design, operation and
            functionalities of, and services comprising, Transfast. Alternate
            designs, operations and functionalities of, and services comprising,
            Transfast may be available, from time to time, to participants on
            separate terms outside of what is represented here. The
            availability, operations and functionalities of, and services
            comprising, Transfast may vary by location. Transfast makes no
            representations as to any aspect of the service provided by third
            parties.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Middle content end-->

<!-- footer section start -->
<app-footer></app-footer>
<!-- footer section end -->

<!-- <router-outlet></router-outlet> -->