export class TransactionStatusResult {
    idBranch: string;
    idFlagReceiver: string;
    idTrackerStatus: number;
    trackerStatus: string;
    trackerStatusDesc: string;
    idCompanyType: string;
    sendCountry: string;
    receiveCountry: number;
    payoutMethod: string;
    receiveCountryName: string;
    sendCountryName: string;
}