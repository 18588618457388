import { Component } from '@angular/core';
import { TransactionTrackerService } from './services/transaction-tracker.service'; // import service
import { TransactionStatusResult } from './model/TransactionStatusResult'; //import class
import { first } from 'rxjs/operators';
import { ACTION } from './constant';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'TransfastRevamp';
  token: string;
  transactionStatusResult = new TransactionStatusResult();
  tfpin = '';
  recaptcha = '1234';
  isDivShow: boolean = false;
  constructor(
    private _transactionTrackerService: TransactionTrackerService,
    private _http: HttpClient,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.isDivShow = false;
  }

  TfpinOnKeyUp() {
    this.isDivShow = false;
  }

  GetTransactionStatus() {
    this._loggingService.logTrace('TF PIN: ' + this.tfpin.trim());
    if (this.tfpin.trim() != '') {
      this._transactionTrackerService
        .GetAuthToken(ACTION.GET_TOKEN.toString())
        .subscribe({
          next: (data) => {
            if (data) {
              this.token = data;
              this.GetTransactionStatusDetails();
            } else {
              alert("Couldn't get Auth Token!");
            }
          },
          error: () => {
            alert('Something went wrong, please try again after sometime!');
          },
        });
    } else {
      alert('Please provide TF PIN!');
    }
  }

  GetTransactionStatusDetails() {
    this._transactionTrackerService
      .GetTransactionStatusFromAPI(
        ACTION.GET_TRANSACTION_INFO.toString(),
        this.tfpin,
        this.recaptcha,
        this.token
      )
      .pipe(first())
      .subscribe({
        next: (response) => {
          if (response['id_flag_receiver'] === null) {
            alert(this.tfpin + '- Entered TF Pin is incorrect!');
            this.isDivShow = false;
          } else {
            (this.transactionStatusResult.trackerStatusDesc =
              response['tracker_status_desc']),
              (this.transactionStatusResult.trackerStatus =
                response['tracker_status']);
            this.isDivShow = true;
          }
          return this.transactionStatusResult;
        },
        error: () => {
          alert('Something went wrong, please try again after sometime!');
        },
      });
  }
}
