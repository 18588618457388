<!-- header start -->
<div class="bgcolor">
  <div class="container top_main_section">
    <div class="row">
      <div class="col-md-12">
        <div class="navbar-brand">
          <img src="assets/images/transfast.svg" alt="transfast" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- header end -->

<!-- banner start -->
<div class="large banner_img">
  <div class="container">
      <div class="row">
          <div class="col-8">
              <h2 class="banner_heading">One connection to reach the world</h2>
              <p class="banner_text">Transfast is a network provider that supports cross-border remittance services of Originating Institutions in the Gulf Cooperation Council countries.</p>
              <p class="banner_text">Transfast also operates a cash payout network with approximately 280,000* cash pick up locations globally.</p>
              <p>*as of March, 2022</p>
          </div>
      </div>
  </div>
</div>

 <div class="mobile">
  <div class="container">
      <div class="col">
          <div class="row">
              <img src="../../assets/images/Artboard.png">
          </div>
          <div class="row" style="background-color: #f3f0ee;">
              <h2 class="banner_heading">One connection to reach the world</h2>
              <p class="banner_text">Transfast is a network provider that supports cross-border remittance services of Originating Institutions in the Gulf Cooperation Council countries.</p>
              <p class="banner_text">Transfast also operates a cash payout network with approximately 280,000* cash pick up locations globally.</p>
              <p >*as of March, 2022</p>
          </div>
      </div>
  </div>
</div>  

<!-- banner end -->
