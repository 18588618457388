<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="footer-col">
                <h4>Policies and more information</h4>
                <ul>
                    <li>
                        <a href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html"
                            target="_blank" rel="noopener">
                            Global Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.mastercard.us/en-us/vision/who-we-are/terms-of-use.html" target="_blank"
                            rel="noopener">
                            Terms of Use
                        </a>
                    </li>
                    <li>
                        <a href="https://b2b.mastercard.com/cross-border-services/legal/" target="_blank"
                            rel="noopener">
                            Legal Information
                        </a>
                    </li>
                    <li>
                        <a href="https://b2b.mastercard.com/media/nivg5f0f/mastercard-cross-border-services-licensing-info.pdf"
                            target="_blank" rel="noopener">
                            Licensing
                        </a>
                    </li>
                    <li>
                        <a href="https://b2b.mastercard.com/cross-border-services/" target="_blank" rel="noopener">
                            Learn more
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>