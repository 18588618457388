import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TransactionTrackerService {
  constructor(private http: HttpClient) {}

  public GetAPIUrl(env: string) {
    let baseURL;
    if (env == 'DEV') {
      baseURL = process.env.HUBSPOT_API_URL_DEV.toString();
    } else if (env == 'UAT') {
      baseURL = process.env.HUBSPOT_API_URL_UAT.toString();
    } else if (env == 'PROD') {
      baseURL = process.env.HUBSPOT_API_URL_PROD.toString();
    }
    return baseURL;
  }

  /*Get TransactionStatusDetails method*/
  GetTransactionStatusFromAPI(
    action: string,
    tfpin: string,
    recaptcha: string,
    Token: string
  ): Observable<any> {
    const baseURL = this.GetAPIUrl(process.env.HUBSPOT_API_ENVIRONMENT.toString());
    let params = new HttpParams();
    params = params.append('tfPin', tfpin);
    params = params.append('recaptcha', recaptcha);
    const headers = {
      Authorization: 'Bearer ' + Token,
      'content-type': 'application/json',
    };
    return this.http.get<any>(baseURL + action, { headers, params });
  }

  /*Get Token  method*/
  GetAuthToken(action: string): Observable<any> {
    const baseURL = this.GetAPIUrl(process.env.HUBSPOT_API_ENVIRONMENT.toString());
    const headers = { 'content-type': 'application/json' };
    const body = {
      Username: process.env.HUBSPOT_API_USERNAME.toString(),
      Password: process.env.HUBSPOT_API_PASSWORD.toString(),
    };
    return this.http.post(baseURL + action, body, {
      headers: headers,
    });
  }
}